.hobbies{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
    font-size: $pSizesPhone;
    & h1 {
        text-align: center;
    }
    & h2 {
        font-size: 28px;
    }
    & section {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        width: 90%;
        & h3 {
            font-size: 28px;
            text-align: center;
        }
        article {
            display: flex;
            flex-direction: column;
            gap: 20px;
        }
    }
    & .events{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 20px;
    }
    & .button-link {
        background-color: $color-one;
    }
}
.hobbies-bloc {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    & .hobbies-card-placement {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 3rem 1rem;
        border-bottom: 1px solid rgba($color: $color-two, $alpha: .3);
    }
}

@media (min-width: $phoneScreenSize) {
    .hobbies{
        padding-top: 50px;
        font-size: $pSizesDesktop;
        & h2 {
            font-size: 35px;
            width: 100%;
            text-align: center;
        }
        & section {
            max-width: none;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: center;
            gap: 50px;
            width: 90%;
            & h3 {
                font-size: 35px;
            }
            article {
                gap: 50px;
                margin-bottom: 30px;
            }
        }
        & .events{
            gap: 50px;
        }
    }    
    .hobbies-bloc {
        flex-direction: row;
        flex-wrap: wrap;
        align-items: stretch;
        justify-content: center;
        gap: 0;
        .hobbies-card-placement {
            width: 30%;
            min-width: 390px;
        }
    }
}