.main-home{
    padding: 0;
    padding-bottom: 50px;
    .title-home {
        padding: 0 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2rem;
        & h3 {
            font-style: italic;
            font-size: $h3SizesPhone;
        }
    } 
    & .event {
        margin-bottom: 0;
        padding: 0 15px;
    }   
}

.home-description{
    display: flex;
    flex-direction: column;
    gap: 5rem;
    padding: 0 15px;
    padding-top: 3rem;
    & article {
        display: flex;
        flex-direction: column;
        gap: 20px;
        & h3 {
            font-size: $h3SizesPhone;
            margin-left: 50px;
        }
        & p {
            font-size: $pSizesPhone;
            text-align: center;
        }
        & ul {
            align-self: center;
            display: flex;
            flex-direction: column;
            gap: 10px;
            & li {
                display: flex;
                align-items: center;
                gap: 7px;
                & p {
                    text-align: start;
                }
                & i {
                    color: $color-two;
                }
            }
        }
        & a {
            align-self: center;
        }
    }
    & div {
        display: flex;
        flex-direction: column;
        gap: 5rem;
    }
    .button-link {
        padding: 1rem 5rem;
    }
}

@media (min-width: $phoneScreenSize) {
    .main-home{
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        .title-home {
            max-width: $desktopScreenSize;
            & h3 {
                font-size: $h3SizesDesktop;
            }
        } 
        & .event {
            margin-top: 0;
            margin-bottom: 0;
            padding: 0 15px;
        } 
    }
    section {
        max-width: 1240px;
        margin: auto;
    }
    .home-description{
        justify-content: center;
        & article {
            gap: 35px;
            & h3 {
                font-size: $h3SizesDesktop;
                margin-left: 70px;
            }
            & p {
                font-size: $pSizesDesktop;
            }
            & ul {
                gap: 15px;
                & li {
                    gap: 7px;
                }
            }
        }
        & div {
            flex-direction: row;
            justify-content: center;
        }
    }
}