.specificityBloc {
    width: 75%;
    margin: 30px auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-family: $policesGlobalBold;
    & article {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;
        color: $color-one;
        padding: 20px 10px;
        border-radius: 10px;
        background-position: center;
        overflow: auto;
        & h3 {
            font-size: $h3SizesPhone;
        }
        & ul {
                display: flex;
                flex-direction: column;
                gap: 5px;
            & h4 {
                font-size: 20px;
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 5px;
                margin: 5px 0;
            }
            & li {
                font-size: $pSizesPhone;
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 5px;
            }            
        }
        & i {
            font-size: 15px;
        }
    }

    & .capacity{
        background-color: rgba($color: $color-mid, $alpha: .85);
        .floorCapacity-bloc {
            width: 95%;
            flex-direction: column;
            align-items: flex-start;
            ul {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                width: 85%;
                margin: auto;
                opacity: .85;
                li {
                    margin: 0;
                    display: flex;
                    flex-direction: row;
                    gap: 5px;
                    i {
                        font-size: 5px;
                    }
                }
            }
        }
    }
    & .equipment{
        background-color: rgba($color: $color-low, $alpha: .85);
        & ul {
            opacity: .85;
        }
    }
    & .services{
        background-color: rgba($color: $color-high, $alpha: .85);
        & ul {
            opacity: .85;
        }
    }
}

@media (min-width: $phoneScreenSize) {
    .specificityBloc {
        width: 95%;
        margin: 50px auto;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
        gap: 10px;
        & article {
            justify-content: start;
            gap: 1.5rem;
            width: 380px;
            height: 650px;
            padding: 0;
            color: $color-two;
            & h3 {
                font-size: $h3SizesDesktop;
                margin-top: 20px;
            }
            & ul {
                gap: 10px;
                & h4 {
                    font-size: 24px;
                }
                & li {
                    font-size: $pSizesDesktop;
                }
            }
            & i {
                font-size: 18px;
            }
        }
        
        & .capacity{
            background-attachment: inherit;
            border-radius: 15px 0 0 15px;
            div{
                width: 95%;
                height: 85%;
                overflow: auto;
                ul {
                    width: 90%;
                    margin: auto;
                }
            }
        }
        & .equipment{
            background-attachment: inherit;
            border-radius: 0;
        }
        & .services{
            background-attachment: inherit;
            border-radius: 0 15px 15px 0;
        }
    }
}