.fullScreenGallery {
    position: relative;
    z-index: 0;
    width: 100%;
    height: 100vh;
    font-size: $pSizesPhone;
    max-width: none;
    .picture-gallery {
        position: relative;
        height: 100vh;
        background-color: $color-one;
        .current-picture-bloc {
            height: 100vh;
        }
    }
    .content-bloc {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        max-width: none;
        & .locationHome-title {
            flex-direction: column;
            & a {
                width: 100px;
            }
        }
        & article {
            display: flex;
            flex-direction: column;
            align-items: center;
            img {
                width: 100px;
            }
            h1{
                font-family: $policesTitle;
                font-size: 40px;
                text-align: center;
                margin: 20px 0;
                letter-spacing: 2px;
            }
            h2{
                font-family: $policesGlobalBold;
                font-size: 30px;
                text-align: center;
            }
            h3{
                font-family: $policesGlobalBold;
                font-size: $h3SizesPhone;
                text-align: center;
            }
        }
        & .locationHome-list {
            gap: 30px;
            div {
                display: flex;
                align-items: center;
                gap: 15px;
                h3 {
                    text-decoration: underline;
                    font-size: 20px;
                    letter-spacing: 2px;
                }
                i {
                    font-size: 20px;
                    text-decoration: none;
                }
            }
            ul {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 2rem;
                li {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    font-size: $pSizesPhone;
                    text-align: center;
                    opacity: .8;
                    a {                        
                        display: flex;
                        justify-content: center;
                        width: 100%;
                    }
                }
            }
            img {
                height: 55px;
                width: auto;
            }
        }
    }
    .fullScreenGallery-basic {
        top: 2rem;
        gap: 3rem;
        height: 75vh;
        & .locationHome-title {
            gap: 3rem;
        }
    }
    & .picture-zoom {
        display: none;
    } 
}

@media (min-width: $phoneScreenSize) {
    .fullScreenGallery {
        max-height: 100vh;
        overflow: hidden;
        .picture-gallery {
            position: relative;
            height: 100vh;
            background-color: $color-one;
            .current-picture-bloc {
                width: 100vh;
                height: auto;
            }
        }
        font-size: $pSizesDesktop;
        .content-bloc {
            & .locationHome-title{
                flex-direction: row-reverse;
                align-items: flex-start;
                gap: 3rem;
                margin-top: 5rem;
                & .title-bloc {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                }
                & a {
                    width: auto;
                    margin-top: 15px;
                    & img {
                        height: 65px;
                        width: auto;
                    }
                }
            }
            & article {
                display: flex;
                flex-direction: column;
                align-items: center;
                img {
                    width: 200px;
                    margin-right: -20px;
                }
                h1{
                    font-size: 70px;
                    margin: 0;
                }
                h2{
                    align-self: flex-end;
                    font-size: 40px;
                }
                h3{
                    font-size: $h3SizesDesktop;
                    align-self: flex-end;
                }
            }
            & .locationHome-list {
                gap: 30px;
                div {
                    gap: 15px;
                    h3 {
                        font-size: 30px;
                    }
                    i {
                        font-size: 25px;
                        text-decoration: none;
                    }
                }
                ul {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    flex-wrap: wrap;
                    gap: 100px;
                    li {
                        font-size: $pSizesDesktop;
                        width: 300px;
                        a {
                            transition: .2s;
                            justify-content: flex-start;
                            &:hover {
                                scale: 1.05;
                                transition: .2s;
                            }
                        }
                    }
                }
                img {
                    height: 80px;
                    width: 280px;
                    object-fit: contain;
                }
            }
        }
    }
}