.prices-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    gap: 50px;
    margin-bottom: 30px;
    font-size: $pSizesPhone;
    & h1 {
        font-size: 28px;
    }
    & section {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 15px;
        width: 90%;
        p a {
            text-decoration: underline;
        }
    }
    & .button-link {
        margin: 20px 0;
    }

    .locations{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        gap: 30px;
        & ul {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 25px;
            & li {
                & img {
                    height: 70px;
                }
                & h2 {
                    font-size: $h3SizesPhone;
                    text-align: end;
                }
            }
            & .titleClickable {
                display: flex;
                align-items: flex-end;
                gap : 30px;
                
                & i {
                    margin-bottom: 10px;
                    font-size: 30px;
                    transition: .2s;
                    opacity: .8;
                }
            }
            & .locationDetails{
                display: none;
            }
        }
        & .activeLocationDetails {
            width: 100%;
            & .titleClickable {
                & i {
                    rotate: -180deg;
                    transition: .2s;
                }
            }
            & .locationDetails{
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 15px;
                width: 100%;
                & .prices-bloc {
                    & ul {
                        align-items: flex-start;
                        gap: 10px;
                    }
                }
            }
        }
    }
}

@media (min-width: $phoneScreenSize) {
    .prices-main {
        gap: 50px;
        margin-bottom: 0;
        font-size: $pSizesDesktop;
        & h1 {
            font-size: 35px;
        }    
        .location-select{
            .locations{
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-around;
                align-items: flex-start;
                gap: 70px;
                & ul {
                    gap: 80px;
                    & li {
                        & h2 {
                            font-size: $h3SizesDesktop;
                        }
                        & img {
                            height: 120px;
                        }
                    }
                    & .titleClickable {
                        gap : 25px;
                        transition: .2s;
                        & i {
                            font-size: 55px;
                        }
                        &:hover {
                            cursor: pointer;
                            scale: 1.02;
                            transition: .2s;
                        }
                    }
                }
                & .activeLocationDetails {
                    width: auto;
                    & .locationDetails{
                        gap: 30px;
                        & .prices-bloc {
                            & ul {
                                gap: 15px;
                            }
                        }
                    }
                }
            }
        }
    }
}