.eventLink{
    width: 300px;
    height: 360px;
    overflow: hidden;
    color: $color-one;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    font-family: $policesGlobalBold;
    background-color: rgba($color-two, .05);
    border: 2px solid rgba($color-two, .1);
    padding: 1rem 0;    
    & h4 {
        font-size: $h3SizesPhone;
        color: $color-two;
        text-align: center;
    }
    & .bloc-img {
        overflow: hidden;
        height: 50%;
        padding-bottom: 10px;
        & img {
            height: 100%;
        }
        & .spinnerLoader {
            position: relative;
        }
    }
    & .card-quote {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 90%;
        padding: 10px;
        color: rgba($color-two, .75);
        height: 50%;
        overflow: auto;
        & ul {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 0 5px;
            :nth-child(1){
                width: 100%;
                text-align: center;
                margin-bottom: 10px;
            }
        }
        & p {
            text-align: center;
            padding: 5px 0;
        }
    }
}

@media (min-width: $phoneScreenSize) {
    .eventLink{
        width: 375px;
        height: 470px;        
        transition: .5s;        
        &:hover{
            scale: 1.015;
            transition: .2s;
            background-color: rgba($color-two, .15);
        }
        & h4 {
            font-size: $h3SizesDesktop;
        }
        & .bloc-img {
            height: 270px;
            width: 210px;
            & .spinnerLoader {
                position: relative;
            }
        }
        & div {
            & ul {
                gap: 0 5px;
                :nth-child(1){
                    width: 100%;
                }
            }
            & p {
                padding: 20px 0;             
            }
        }
    }
}