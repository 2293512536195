.inProgressLoc{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    font-size: $pSizesPhone;
    & ul {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        align-self: center;
        gap: 5px;
        margin-right: 50px;
        @media (min-width: $phoneScreenSize) {
            flex-wrap: wrap;
            gap: 15px;   
            margin-right: 80px;     
        }
        & a {
            transition: .2s;
            &:hover {
                scale: 1.02;
                transition: .2s;
            }
        }
        & li a {
            display: flex;
            flex-direction: row-reverse;
            align-items: flex-end;
            img {
                height: 60px;
                @media (min-width: $phoneScreenSize) {
                    height: 105px;        
                }
            }
            i {
                margin-left: -50px;
                margin-bottom: -7px;
                font-size: 20px;
                @media (min-width: $phoneScreenSize) {
                    margin-left: -80px;
                    margin-bottom: -7px;
                    font-size: 30px;        
                }
            }
        }
    }
    & .button-link {
        display: block;
        width: 75%;
        margin: auto;
        @media (min-width: $phoneScreenSize) {
            width: 255px;     
        }
    }
    @media (min-width: $phoneScreenSize) {
        font-size: $pSizesDesktop;     
        gap: 60px;
    }
}

