@keyframes scaleUp {
    0% {
        transform: scale(.8);
    }
    50% {
        transform: scale(1.5);
    }
    100% {
        transform: scale(.8);
    }
}
@keyframes changeOpacity {
    0% {
        opacity: .4;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: .4;
    }
}

@keyframes loading-spinner{
    to{
        transform: rotate(2turn);
    }
}

@keyframes translateToRight {
    0% {
        transform: translateX(-99999px);
      }
    
    100% {
        transform: translateX(0);
    }
}
@keyframes translateToLeft {
    0% {
        transform: translateX(99999px);
      }
    
    100% {
        transform: translateX(0);
    }
}

@keyframes scaling-image-animation {
    0% {
    //   opacity: 0.8;
    }
    100% {
      transform: scale(1.5);
    }
}

@keyframes changeLinearGradientBg {
    0% {background-image: linear-gradient(0deg, rgba($color-two, 0.4), rgba($color-one, 0));}
    25% {background-image: linear-gradient(0deg, rgba($color-two, 0.45), rgba($color-one, 0));}
    50% {background-image: linear-gradient(0deg, rgba($color-two, 0.5), rgba($color-one, 0));}
    75% {background-image: linear-gradient(0deg, rgba($color-two, 0.55), rgba($color-one, 0));}
    100% {background-image: linear-gradient(0deg, rgba($color-two, 0.6), rgba($color-one, 0));}
}