header{
    width: 100%;
    height: 80px;
    position: fixed;
    top: 0;
    z-index: 10000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: $color-one;
    box-shadow: 0px 3px 5px rgba($color: $color-two, $alpha: .6);
    & .header-phone {
        width: 100%;
        height: 80px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        & img {
            width: 130px;
            margin-left: 20px;
        }
        & .burger-toggle{
            display: flex;
            flex-direction: column;
            gap: 5px;
            cursor: pointer;
            margin-right: 20px;
            & span {
                height: 3px;
                width: 30px;
                border-radius: 10px;
                background-color: $color-two;
                transform: rotate(0);
                transition: .2s;
            }
        }
    }
    & nav {
        display: none;
    }
}

.active{
    font-size: 20px;
    height: auto;
    .burger-toggle{
        :nth-child(1){
            transform:  translateY(3px) rotate(45deg);
            transition: transform .2s ease-in-out;
        }
        :nth-child(3){
            transform: translateY(-4px) rotate(-45deg);
            transition: transform .2s ease-in-out;
        }
        :nth-child(2){
            display: none;
            transition: .2s;
        }
    }
    & nav {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding-bottom: 20px;
        gap: 10px;
        & div {
            cursor: pointer;
            & div {
                display: flex;
                align-items: center;
                gap: 10px;
                & i {
                    font-size: 17px;
                    transition: .2s;
                }
            }
            & ul {
                display: none;
            }
        }
        & .activeLocationList{
            display: flex;
            flex-direction: column;
            align-items: center;
            & div {
                & i {
                    rotate: -180deg;
                    transition: .2s;
                }
            }
            & ul {
                margin: 15px 0;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                justify-content: center;
                gap: 15px;
                font-size: 18px;
                height: 100px;
                li {
                    display: flex;
                    align-items: center;
                    gap: 15px;
                    opacity: .8;
                    i {
                        font-size: 13px;
                    }
                }
                & .list-bar {
                    width: 150px;
                    border-top: solid 1px $color-two;
                }
            }
        }
    }
    & .tablet-bar{
        display: none;
    }
}

@media (min-width: $phoneScreenSize) {
    header {
        width: auto;
        position: initial;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        box-shadow: none;
        height: 150px;
        padding: 1rem 0;
        .header-phone{
            & img {
                width: 170px;
                margin: 0;
            }
            width: auto;
            .burger-toggle{
                display: none;
            }
        }
        & nav {
            display: flex;
            flex-direction: row;
            align-items: normal;
            text-align: initial;
            padding-bottom: 0;
            gap: 15px;
            font-size: 19px;
            & div {
                cursor: pointer;
                display: flex;
                flex-direction: column;
                align-items: center;
                & div {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 10px;
                    & i {
                        text-decoration: none;
                        font-size: 17px;
                        transition: .2s;
                    }
                }
                & div p:hover {
                    text-decoration: underline;
                }
                & ul {
                    display: none;
                }
            }
            div:hover{
                z-index: 1;
                & div {
                    & i {
                        rotate: -180deg;
                        transition: .2s;
                    }
                }
                & ul {
                    position: absolute;
                    z-index: -100;
                    top: 140px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-end;
                    gap: 10px;
                    font-size: 16px;
                    background-color: rgba($color: $color-three, $alpha: .6);
                    width: 150px;
                    height: 110px;
                    padding: 5px;
                    border-radius: 0 0 5px 5px;
                    li {
                        display: flex;
                        align-items: center;
                        gap: 15px;
                        opacity: 1;
                        i {
                            font-size: 13px;
                        }
                    }
                    & .list-bar {
                        align-self: center;
                        width: 120px;
                        border-top: solid 1px $color-two;
                    }
                }
            }
        }
        & a:hover {
            text-decoration: underline;
        }
    }
}

@media (min-width: $desktopScreenSize) {
    header {
        width: auto;
        position: initial;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-shadow: none;
        & .header-phone{
            width: auto;
            & .burger-toggle{
                display: none;
            }
        }
        & nav {
            display: flex;
            flex-direction: row;
            align-items: normal;
            text-align: initial;
            padding-bottom: 0;
            gap: 20px;
            font-size: 21px;
            letter-spacing: 1px;
            & div {
                cursor: pointer;
                & div {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    & i {
                        font-size: 19px;
                        transition: .2s;
                    }
                }
                & ul {
                    display: none;
                }
            }
            & div:hover{
                & div {
                    & i {
                        rotate: -180deg;
                        transition: .2s;
                    }
                }
                & ul {
                    font-size: 19px;
                    padding-top: 10px;
                    padding-bottom: 15px;
                }
            }
        }
    }
}

.header-home {
    width: 100%;
    position: fixed;
    background-color: transparent;
    box-shadow: none;
    height: auto;
    align-items: flex-end;
    & .header-phone{
        height: 50px;
        @media (min-width: $phoneScreenSize) {
            display: none;
        }
        img {
            display: none;
        }
    }
    & nav {
        align-items: flex-end;
        margin-right: 20px;
        margin-left: 20px;
        & div:hover {
            ul {
                @media (min-width: $phoneScreenSize) {
                    top: 0;
                    height: 130px;
                    justify-content: flex-end;
                }
            }
        }
        a {
            color: $color-one;
        }
    }
}

.active-header-home {
    display: flex;
    width: auto;
    right: 0;
    background-color: rgba($color: $color-two, $alpha: .85);
    color: $color-one;
    .header-phone .burger-toggle {
        span {
            background-color: $color-one;
        }
    }
    & nav .activeLocationList {
        div {
            align-self: flex-end;
        }
        ul {
            .list-bar{
            border-top: solid 1px $color-one;
            }
        }
    }
}

@media (min-width: $phoneScreenSize) {
    .header-home {
        width: 100%;
        position: absolute;
        align-items: center;
        justify-content: center;
        height: 50px;
        background-image: none;
        & nav {
            align-items: center;
            margin-right: 0;
            & div {
                @media (min-width: $phoneScreenSize) {
                    margin: 0 5px;
                }
            }
            & div:hover {                
                ul {
                    @media (min-width: $phoneScreenSize) {
                        top: 15px;
                        height: 130px;
                        justify-content: flex-end;
                    }
                }
            }
            a {
                display: inline-block;
                color: $color-two;
                transition: .2s;
                &:hover {
                    scale: 1.05;
                    transition: .2s;
                }
            }
        }
    }
}