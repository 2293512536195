.reserved {
    background-color: $color-four!important; 
    color: $color-two!important; 
    font-weight: bold; 
}
.start-date {
  font-weight: bold; 
  background-color: $color-four!important;
  color: $color-one!important;
  background: linear-gradient(-45deg, rgba($color-four,1) 0%, rgba($color-four,1) 50%, rgba(255,255,255,0) 50%, rgba(255,255,255,0) 100%) !important;
}
.end-date {
  font-weight: bold; 
  background-color: $color-four!important;
  color: $color-one!important;
  background: linear-gradient(135deg, rgba($color-four,1) 0%, rgba($color-four,1) 50%, rgba(255,255,255,0) 50%, rgba(255,255,255,0) 100%) !important;
}
.reserved.react-calendar__tile:focus  {
    background-color: $color-four!important; 
    color: $color-two!important; 
    font-weight: bold; 
}
.reserved:hover.react-calendar__tile:hover {
    background-color: $color-four!important; 
    color: $color-two!important; 
    font-weight: bold; 
}

  .react-calendar{
    background-color: $color-two;
    color: $color-one;
    padding: 10px;
    border-radius: 10px;
    & .react-calendar__navigation {
        margin: auto;
    }
  }

  .react-calendar__tile:hover {
    background-color: transparent !important;
    color: inherit !important; 
    cursor: default !important; 
    border: none !important; 
  }
  .react-calendar__tile:focus {
    background-color: transparent !important;
    color: inherit !important; 
    cursor: default !important; 
    border: none !important; 
  }

.calendar-quote{
  margin: 10px 0;
  i {
    color: $color-four;
    border: 1px solid $color-two;
    border-radius: 5px;
  }
}

@media (max-width: 600px) {
  .react-calendar {
    font-size: 14px; 
    width: 300px!important;
  }
}