.location-template{
    flex-direction: column;
    @media (min-width: $phoneScreenSize) {
              display: flex;      
    }
    & h1 {
        width: 100%;
        text-align: center;
        padding: 15px 0;
        & img {
            height: 70px;
            display: inline;
        }
    }
    & h2 {
        text-decoration: underline;
        font-size: $h3SizesPhone;
    }
    & p {
        font-size: $pSizesPhone;
        opacity: .85;
    }
    & section {
        margin: 30px auto;
        @media (min-width: $phoneScreenSize) {
            margin: 60px auto;
        }
    }
    & .picture-gallery {
        width: 90%;
        max-width: $desktopScreenSize;
        margin: auto;
        margin-top: 0; 
        position: relative;
        z-index: 0;
        @media (min-width: $phoneScreenSize) {
            padding: 15px 0;
            background-color: $color-three;
            border-radius: 15px;
        }
        & .current-picture-bloc {
            height: 250px;
            @media (min-width: $phoneScreenSize) {
                height: 400px;
            }
            & img {
                max-width: 700px;
                opacity: .7;
                @media (min-width: $phoneScreenSize) {
                    max-width: 1000px;
                }
            }
        }
        &:hover {
            .slick-prev {
                opacity: 1;
                transition: .2s;
            }
            .slick-next {
                opacity: 1;
                transition: .2s;
            }
        }
        .slick-prev {
            display: initial;
        }
        .slick-next {
            display: initial;
        }
        
        .slick-next:before, .slick-prev:before {
            display: block;
        }
        .slick-dots li.slick-active button:before {
            display: block;
        }
        
        .slick-dots li button:before {
            display: initial;
        }
        .slick-dots{
            bottom: 10px;
            @media (min-width: $phoneScreenSize) {
                    bottom: -20px;
            }
        }
    }
    & .description {
        width: 80%;
        margin: auto;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    & .google-maps {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 80%;
        gap: 20px;
        & .address-bloc {
            display: flex;
            flex-direction: column; 
            gap: 10px;
            .requirement{
                margin: 1rem 0;
                & h2 {
                    margin-bottom: 20px;
                }
                & ul {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-around;
                    gap: 10px;
                    & li {
                        display: flex;
                        align-items: center;
                        gap: 5px;
                        width: 40%;
                        & i {
                            font-size: 15px;
                        }
                    }
                }
            }
            & address {
                font-size: $pSizesPhone;
                margin: 1rem 0;
                width: 100%;
            }
            & .button-link {
                align-self: center;
                width: 150px;
                margin: 1rem 0;
            }
        }
    }
}

@media (min-width: $phoneScreenSize) {
    .location-template{
        padding: 0;
        & h1 {
            & img {
                height: 130px;
            }
        }
        & h2 {
            font-size: $h3SizesDesktop;
        }
        & p {
            font-size: $pSizesDesktop;
        }
        & .description {
        width: 75%;
        margin: 50px auto;
        display: flex;
        flex-direction: column;
        gap: 15px;
        }
        & .google-maps {
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
            width: 80%;
            gap: 20px;
            & .address-bloc {
                display: flex;
                flex-direction: column;
                gap: 1rem;
                & .requirement{
                    & h2 {
                        margin-bottom: 1rem;
                    }
                    & ul {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-around;
                        gap: 10px;
                        & li {
                            display: flex;
                            align-items: center;
                            gap: 5px;
                            width: 40%;
                            & i {
                                font-size: 15px;
                            }
                        }
                    }
                }
                & .button-link {
                    align-self: flex-start;
                }
                & address {
                    font-size: $pSizesDesktop;
                }
            }
        }
    }
}