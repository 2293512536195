.prices-bloc {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
    .table-container {
        overflow-x: auto;
        border: 1px solid $color-two;
        width: 100%;
        max-width: 472px;
      }
    table {
        border: 1px solid $color-two;
        border-radius: 4px;
        border-collapse : collapse;
        min-width: 400px;
    }
    th, td {
        padding: 3px;
        text-align: center;
        border: 1px solid $color-two;
        color: $color-one;
    }
    th {
        background-color: rgba($color: $color-two, $alpha: .5);
    }
    .high {
        background-color: $color-high;
    }
    .mid {
        background-color: $color-mid;
    }
    .low {
        background-color: $color-low;
    }
    ul {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 10px;
        & :nth-child(1) {
            i {
                color: $color-high;
            }
        }
        & :nth-child(2) {
            i {
                color: $color-mid;
            }
        }
        & :nth-child(3) {
            i {
                color: $color-low;
            }
        }
        & li {
            display: flex;
            align-items: center;
            gap: 5px;
            & i {
                border: 1px solid $color-two;
                border-radius: 5px;
            }
        }
    }
}
.table-quote {
    font-size: 12px;
    margin-top: -10px;
}

@media (min-width: $phoneScreenSize) {
    .prices-bloc {
        gap: 20px;
        & .table-container{
            max-width: none;
            border: none;
        }
        table {
            border: 2px solid $color-two;
        }
        th, td {
            padding: 7px;
            border: 2px solid $color-two;
        }
        ul {
            gap: 15px;
            & li {
                gap: 10px;
            }
        }
    }
    .table-quote {
        width: 550px;
        font-size: 17px;
        margin-top: -15px;
    }
}