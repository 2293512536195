.button-link{
    padding: 8px 20px;
    border-radius: 20px;
    color: $color-two;
    background-color: rgba($color: $color-one, $alpha: .3);
    font-family: $policesGlobalBold;
    font-size: $pSizesPhone;
    border: 2px solid;
    box-shadow: 1px 1px 10px;
    text-align: center;
}

@media (min-width: $phoneScreenSize) {
    .button-link {
        padding: 13px 25px;
        font-size: $pSizesDesktop;
        transition: .2s;
        &:hover {
            scale: 1.02;
            transition: .2s;
            color: $color-two;
            border: 2px solid $color-two;
            box-shadow: 1px 1px 10px $color-two;
        }
    }
}