.servicesCards{
    width: 300px;
    height: 300px;
    overflow: hidden;
    color: $color-two;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    font-family: $policesGlobalBold;
    & .bloc-img {
        & img {
            width: 100%;
            height: 100%;
        }
        & .spinnerLoader {
            position: relative;
        }
    }
    & .bloc-quote {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        gap: 15px;
        width: 90%;
        padding: 20px 0;
        & h3 {
            font-size: $h3SizesPhone;
        }
    }
}

@media (min-width: $phoneScreenSize) {
    .servicesCards{
        width: 350px;
        height: 350px;
        & h3 {
            font-size: $h3SizesDesktop;
        }
        & .bloc-img {
            height: 210px;
            width: 320px;
            & img {
                width: 320px;
            }
            & .spinnerLoader {
                position: relative;
            }
        }
    }
}