.picture-gallery {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    @media (min-width: $phoneScreenSize) {
        margin-top: 0;
    }
    .current-picture-bloc {
        overflow: hidden;
        display: flex!important;
        align-items: center;
        justify-content: center;
        height: 300px;
        @media (min-width: $phoneScreenSize) {
            height: 600px;
        }
        & img {       
            height: 100%;
            opacity: .55;
        }
    }
}

.activeAnimation {
    .slick-active {
        & img {
            animation: scaling-image-animation 15s linear;
            object-fit: contain;
        }
    }
}

.slick-prev {
    display: none;
    opacity: 0;
    left: 10px;
    z-index: 1000;
    width: auto;
    height: auto;
    transition: .2s;
}
.slick-next {
    display: none;
    opacity: 0;
    right: 10px;
    z-index: 1000;
    width: auto;
    height: auto;
    transition: .2s;
}

.slick-next:before, .slick-prev:before {
    display: none;
    font-size: 30px;
    @media (min-width: $phoneScreenSize) {
        font-size: 50px;
    }
}
.slick-dots li.slick-active button:before {
    display: none;
    color: $color-two;
}

.slick-dots li button:before {
    display: none;
    font-size: 6px;
    line-height: 20px;
    width: 20px;
    height: 20px;
    color: $color-two;
}

