.contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4rem;
    font-size: $pSizesPhone;
    padding-bottom: 4rem;
    & h1 {
        font-size: 28px;
    }
    & h2 {
        font-size: $h3SizesPhone;
    }
    section {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 95%;
        & ul {
            display: flex;
            flex-direction: column;
            gap: 5px;
            & li {
                display: flex;
                align-items: center;
                gap: 10px;
                & i {
                    font-size: 15px;
                }
            }
        }
    }
    .localisation {
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
            opacity: .8;
            width: 320px;
            height: 200px;
            object-fit: contain;
        }
    }
    .contact-profil {
        width: 100%;
        & article {
            display: flex;
            justify-content: space-around;
            flex-direction: row;
            align-items: center;
            width: 95%;
            margin: auto;
            padding: 10px 0;
            background-color: rgba($color: $color-two, $alpha: .3);
            border-radius: 15px;
            & div {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 0 10px;
                gap: 10px;   
                width: 60%;             
            }
            & figcaption {
                width: 70px;
                height: 70px;
                background-color: $color-two;
                border-radius: 50%;
                border: solid $color-one 2px;
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;
                img {
                    width: 70px;
                    height: 70px;
                    object-fit: cover;
                }
            }
        }
    }
    .social-link {
        & div {
            display: flex;
            justify-content: center;
            gap: 30px;
            & a {
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;
                padding: 5px;
                background-color: $color-one;
                border-radius: 50%;
                width: 55px;
                height: 55px;
            }
        }
    }
}

@media (min-width: $phoneScreenSize) {
    .contact {
        font-size: $pSizesDesktop;
        background-color: rgba($color-one, 0.8);
        background-blend-mode: darken;
        background-image: url("../../assets/bg-img/img_home_1_1920-min.jpg");
        background-position: center;
        background-size: cover;
        & h1 {
            font-size: 45px;
        }
        & h2 {
            font-size: $h3SizesDesktop;
        }
        section {
            align-items: center;
            & ul {
                gap: 10px;
                & li {
                    & i {
                        font-size: 18px;
                    }
                }
            }
        }
        .contact-profil {
            flex-direction: row;
            justify-content: space-around;
            flex-wrap: wrap;
            & article {
                display: flex;
                justify-content: space-around;
                align-items: center;
                gap: 15px;
                width: 480px;
                height: 130px;
                padding: 0;
                p {
                    padding: 0 10px;
                    text-align: center;
                }
                & figcaption {
                    width: 90px;
                    height: 90px;
                    img {
                        width: 90px;
                        height: 90px;
                    }
                }
            }
        }
        .social-link {
            align-items: center;
            gap: 50px;
            & div {
                gap: 50px;
                & a {
                    width: 100px;
                    height: 100px;
                    transition: .2s;
                    & img {
                        width: 90%;
                    }
                    &:hover {
                        scale: 1.04;
                        opacity: .9;
                        transition: .2s;
                    }
                }
            }
        }
        .localisation {
            align-items: center;
            img {
                width: 800px;
                height: 500px;
            }
        }
    }
}