footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: $color-one;
    gap: 10px;
    padding-top: 20px;
    padding-bottom: 10px;
    width: 100%;
    & .title-footer {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        & h2 {
            font-family: $policesTitle;
            font-size: 30px;
            letter-spacing: 1px;
        }
        & h3 {
            font-size: 15px;
        }
    }
    & ul {
        display: flex;
        align-items: center;
        gap: 20px;
        & i {
            font-size: 7px;
        }
    }
    & .socialLink-list {
        & .link-icon {
            & i {
                font-size: 22px;
            }
        }
    }
    & .link-list{
        font-size: 15px;
        & a {
            text-decoration: underline;
        }
        & i {
            font-size: 10px;
        }
    }
    & p {
        font-size: 11px;
        & a {
            text-decoration: underline;
        }
    }
}

@media (min-width: $phoneScreenSize) {
    footer {
        gap: 15px;
        padding-top: 30px;
        padding-bottom: 10px;
        & .title-footer {
            & h2 {
                font-size: 36px;
                letter-spacing: 2px;
            }
            & h3 {
                font-size: 20px;
            }
        }
        & ul {
            gap: 25px;
            & i {
                font-size: 9px;
            }
        }
        & .socialLink-list {
            & .link-icon {
                & i {
                    font-size: 27px;
                }
                & a {
                    display: inline-block;
                    transition: .2s;
                    &:hover {
                        scale: 1.05;
                        transition: .2s;
                    }
                }
            }
        }
        & .link-list{
            font-size: 20px;
            & a {
                display: inline-block;
                transition: .2s;
                &:hover {
                    scale: 1.05;
                    transition: .2s;
                }
            }
            & i {
                font-size: 15px;
            }
        }
        & p {
            font-size: 16px;
            & a {
                display: inline-block;
                transition: .2s;
                &:hover {
                    scale: 1.05;
                    transition: .2s;
                }
            }
        }
    }
}

.footer-location{
    position: fixed;
    bottom: 10px;
    left: 0;
    background-color: transparent;
    padding: 0;
    & .title-footer{
        display: none;
    }
}