html {
    scroll-behavior: smooth;
    background-color: $color-one;
    color: $color-two;
    font-family: $policesGlobal, $policesDefault;
}

main {
    padding-top: 10rem;
    padding-bottom: 8rem;
    background-color: $color-three;    
    background-repeat: no-repeat;
    background-attachment: fixed;
    @media (min-width: $phoneScreenSize) {
        padding-bottom: 10rem;
    }
    @media (min-width: $desktopScreenSize) {
        padding-bottom: 11rem;
    }
}

a {
    color: $color-two;
    text-decoration: none;
}

h1, h2, h3, h4{
    margin: 0;
    font-weight: 500;
}

p{
    margin: 0;
}

ul, li {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.bloc-img {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-two;
    width: 100%;
    height: 100%;
    // settings width & height in here comp
    img {
        object-fit: contain;
    }
}

.separation-lign {
    width: 100%;
    height: 1px;
    background-color: rgba($color: $color-two, $alpha: .3);
}