.mentions {
    display: flex;
    flex-direction: column;
    font-size: $pSizesPhone;
    padding-right: 1rem;
    padding-left: 1rem;
    & h1 {
        font-size: 30px;
        margin-bottom: 30px;
    }
    & h2 {
        font-size: $h3SizesPhone;
    }
    & section {
        margin: 15px 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    & a {
        text-decoration: underline;
    }
}

@media (min-width: $phoneScreenSize) {
    .mentions {
        font-size: $pSizesDesktop;
        & h1 {
            font-size: 35px;
        }
        & h2 {
            font-size: $h3SizesDesktop;
        }
    }
}