.main-error {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    & h1 {
        text-align: center;
        font-size: 50px;
    }
    & h2 {
        text-align: center;
        font-size: 30px;
    }
    & a {
        margin-top: 20px;
        text-align: center;
        font-size: 20px;
        text-decoration: underline;
        transition: .2s;
    }
    & a:hover {
        scale: 1.05;
        transition: .2s;
    }
}
.loading-page {
    min-height: 12rem;
}