.nextEvent {
    display: flex;
    flex-direction: column;
    gap: 20px;
    & h3 {
        font-size: $h3SizesPhone;
        margin-left: 50px;
    }
    & .event-bloc {
        display: flex;
        font-family: $policesGlobalBold, sans-serif;
        justify-content: space-between;
        align-self: center;
        align-items: center;
        color: $color-one;
        height: 255px;
        overflow: hidden;
        gap: 10px;
        max-width: 300px;
        background-color: rgba($color-two, .8);
        border-radius: 15px;
        & .bloc-img {
            width: 50%;
            img {
                height: 100%;
            }
        }
        
        & ul {
            padding: 15px 10px;
            width: 50%;
            display: flex;
            flex-direction: column;
            gap: 15px;
            font-size: 16px;    
            height: 90%;
            overflow: auto;        
            & li {
                display: flex;
                align-items: baseline;
                gap: 5px;
                & i {
                font-size: 13px;
                color: $color-four;
                }
            }
            & .title-list {
                margin-bottom: 10px;
                align-items: center;
                & h4 {
                    font-size: 19px;
                }
                i {
                font-size: 17px;
                color: #ff9900;
                }
            }
        }
    }
    & a {
        align-self: flex-end;
    }
}

@media (min-width: $phoneScreenSize) {
    .nextEvent {
        gap: 50px;
        & h3 {
            font-size: $h3SizesDesktop;
            margin-left: 70px;
        }
        & .event-bloc {
            height: 350px;
            width: 100%;
            max-width: 900px;
            transition: .2s;
            gap: 20px;
            & .bloc-img {
                height: 100%;
                width: 40%;
            }
            & ul {
                gap: 20px;
                font-size: $pSizesDesktop;
                & li {
                    gap: 10px;
                    & i {
                    font-size: 20px;
                    }
                }
                & .title-list{
                    & h4 {
                        font-size: $h3SizesDesktop;
                    }
                    & i {
                    font-size: $h3SizesDesktop;
                    }
                }
            }
            &:hover {                
                scale: 1.02;
                transition: .2s;
            }
        }
        & a {
            align-self: flex-end;
        }
    }
}