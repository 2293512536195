.spinnerLoader {
    display: none;
    place-items: center;
    position: fixed;
    & div{
        width: 40px;
        padding: 6px;
        aspect-ratio: 1;
        border-radius: 50%;
        background: $color-two;
        --_m: 
            conic-gradient(#0000 10%,#000),
            linear-gradient(#000 0 0) content-box;
        -webkit-mask: var(--_m);
                mask: var(--_m);
        -webkit-mask-composite: source-out;
                mask-composite: subtract;
        animation: loading-spinner 1s infinite linear;
    }
}

.isLoad {
    display: grid;
}

@media (min-width: $phoneScreenSize) {
    .spinnerLoader{
        & div {
            width: 70px;
            padding: 10px;
        }
    }
}