.backTop {
    display: flex;
    width: 50px;
    height: 50px;
    text-align: center;
    background-color: rgba($color-two, .75);
    color: $color-three;
    border-radius: 10px;
    flex-direction: column;
    justify-content: center;
    opacity: 0.7;
    position: fixed;
    bottom: 3%;
    right: 5%;
    transition: .5s;
    z-index: 100000;
    @media (min-width: $phoneScreenSize) {
        display: none;
    }
    & i {
        font-size: 40px;
    }
}

.backTop:hover {
    opacity: 1;
    transition: .5s;
}

#backTop-anchor {
    position:absolute;
    top: 0;
    left: 0;
}