.inProgress{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 56vh;
    & section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        padding: 40px 15px;
        background-color: rgba($color: $color-three, $alpha: .7);
        border-radius: 15px;
        & article {
            display: flex;
            align-items: center;
            gap: 10px;
            text-align: center;
            & h1 {
                font-size: 25px;
            }
            & i {
                font-size: 22px;
            }
        }
        & aside {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 5px;
            & i {
                font-size: 6px;
            }
            & 
            :nth-child(1){
                font-size: 20px;
                margin-right: 10px;
            }
            :nth-child(5){
                font-size: 20px;
                margin: 0 10px;
            }
            :nth-child(9){
                font-size: 20px;
                margin-left: 10px;
            }
        }
        & h2 {
            font-size: 20px;
        }
        & p {
            text-decoration: underline;
            font-size: 15px;
        }
    }
}

@media (min-width: $phoneScreenSize) {
    .inProgress{
        height: auto;
        & section {
            gap: 30px;
            padding: 80px 20px;
            max-width: $desktopScreenSize;
            & article {
                gap: 20px;
                & h1 {
                    font-size: 35px;
                }
                & i {
                    font-size: 40px;
                }
            }
            & aside {
                gap: 20px;
                & i {
                    font-size: 12px;
                }
                & 
                :nth-child(1){
                    font-size: 30px;
                    margin-right: 20px;
                }
                :nth-child(5){
                    font-size: 30px;
                    margin: 0 20px;
                }
                :nth-child(9){
                    font-size: 30px;
                    margin-left: 20px;
                }
            }
            & h2 {
                font-size: 28px;
            }
            & p {
                font-size: 20px;
            }
        }
    }
}

.iconCharge {
    animation: scaleUp 1s backwards infinite;
    @for $i from 1 through 3 {
        &--#{$i} {
            animation-delay: .2s * $i;
        }
    }
}

