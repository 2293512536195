@font-face {
    font-family: 'Vladimir Script';
    src: url('../polices/VladimirScript.ttf');
}
@font-face {
    font-family: 'Cormorant';
    src: url('../polices/Cormorant.ttf');
}
@font-face {
    font-family: 'Cormorant-bold';
    src: url('../polices/Cormorant-Bold.ttf');
}

// Font-Family

$policesGlobal: 'Cormorant';
$policesTitle: 'Vladimir Script';
$policesDefault: 'sans-serif';
$policesGlobalBold: 'Cormorant-bold';


// colors

$color-one: #000000;
$color-two: white;
$color-three: #060606;
$color-four: #90000E;

$color-high: #feca17;
$color-mid: #d3db51;
$color-low: #55a8c8;

$color-oneLight : rgba($color-one, .3);

// Screen Sizes

$phoneScreenSize: 750px;
// $tabletScreenSize:;
$desktopScreenSize: 1240px;

// Text sizes

$h3SizesPhone: 22px;
$h3SizesDesktop: 25px;

$pSizesPhone: 18px;
$pSizesDesktop: 22px;

// Background style

$bgImgHeader: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.dev/svgjs' width='1440' height='150' preserveAspectRatio='none' viewBox='0 0 1440 150'%3e%3cg mask='url(%26quot%3b%23SvgjsMask5064%26quot%3b)' fill='none'%3e%3crect width='1440' height='150' x='0' y='0' fill='url(%26quot%3b%23SvgjsLinearGradient5065%26quot%3b)'%3e%3c/rect%3e%3cpath d='M1440 0L932.74 0L1440 24.93z' fill='rgba(255%2c 255%2c 255%2c .1)'%3e%3c/path%3e%3cpath d='M932.74 0L1440 24.93L1440 59.16L594.0799999999999 0z' fill='rgba(255%2c 255%2c 255%2c .075)'%3e%3c/path%3e%3cpath d='M594.0799999999999 0L1440 59.16L1440 110.33L344.56999999999994 0z' fill='rgba(255%2c 255%2c 255%2c .05)'%3e%3c/path%3e%3cpath d='M344.56999999999994 0L1440 110.33L1440 118.02L301.2199999999999 0z' fill='rgba(255%2c 255%2c 255%2c .025)'%3e%3c/path%3e%3cpath d='M0 150L421.29 150L0 124.35z' fill='rgba(0%2c 0%2c 0%2c .1)'%3e%3c/path%3e%3cpath d='M0 124.35L421.29 150L592.52 150L0 74.19z' fill='rgba(0%2c 0%2c 0%2c .075)'%3e%3c/path%3e%3cpath d='M0 74.19L592.52 150L872.6800000000001 150L0 66.75999999999999z' fill='rgba(0%2c 0%2c 0%2c .05)'%3e%3c/path%3e%3cpath d='M0 66.75999999999999L872.6800000000001 150L1234.1200000000001 150L0 19.89999999999999z' fill='rgba(0%2c 0%2c 0%2c .025)'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask5064'%3e%3crect width='1440' height='150' fill='white'%3e%3c/rect%3e%3c/mask%3e%3clinearGradient x1='77.6%25' y1='-215%25' x2='22.4%25' y2='315%25' gradientUnits='userSpaceOnUse' id='SvgjsLinearGradient5065'%3e%3cstop stop-color='rgba(0%2c 0%2c 0%2c 1)' offset='0'%3e%3c/stop%3e%3cstop stop-color='rgba(34%2c 34%2c 34%2c 1)' offset='0.74'%3e%3c/stop%3e%3cstop stop-color='rgba(0%2c 0%2c 0%2c 1)' offset='1'%3e%3c/stop%3e%3cstop stop-color='rgba(255%2c 255%2c 255%2c 1)' offset='1'%3e%3c/stop%3e%3c/linearGradient%3e%3c/defs%3e%3c/svg%3e");