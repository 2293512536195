.hobbies-bloc-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    padding: 10px;
    gap: 1rem;
    max-width: 400px;
    & h4 {
        font-size: 23px;
        text-align: center;
    }
    & p {
        opacity: .85;
    }
    & .hobbie-logo{
        height: 85px;
        object-fit: contain;
    }
    & img {
        width: 270px;
        height: 170px;
    }
    .climb {
        background-color: #292929;
        height: 100px;
        object-fit: none;
        @media (min-width: $phoneScreenSize) {
            height: 120px;
        }
    }
    & ul {
        display: flex;
        flex-direction: column;
        opacity: .75;
        gap: .5rem;
        margin-bottom: 1.5rem;
        & li {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            overflow: hidden;
            gap: 10px;
        }
    }
    & .picture-gallery {
        width: 270px;
        height: 170px;
        margin: auto;
        margin-top: 0; 
        position: relative;
        z-index: unset;
        @media (min-width: $phoneScreenSize) {
            height: 270px;
            width: 370px;
        }
        & .current-picture-bloc {
            height: 170px;
            opacity: 1;
            @media (min-width: $phoneScreenSize) {
                height: 270px;
            }
            & img {
                max-width: 270px;
                opacity: 1;
                @media (min-width: $phoneScreenSize) {
                    max-width: 370px;
                }
            }
        }
        &:hover {
            .slick-prev {
                opacity: 1;
                transition: .2s;
            }
            .slick-next {
                opacity: 1;
                transition: .2s;
            }
        }
        .slick-prev {
            display: initial;
        }
        .slick-next {
            display: initial;
        }
        
        .slick-next:before, .slick-prev:before {
            display: block;
        }
        .slick-dots li.slick-active button:before {
            display: block;
        }
        
        .slick-dots li button:before {
            display: initial;
        }
    }
    .picture-zoom {
        display: none;
    }
}

@media (min-width: $phoneScreenSize) {
    .hobbies-bloc-list {
        width: 370px;
        & h4 {
            font-size: 23px;
            text-align: center;
        }
        & p {
            align-self: flex-start;
        }
        & .hobbie-logo{
            height: 95px;
        }
        & img {
            width: 370px;
            height: 270px;
        }
        & ul {
            display: flex;
            flex-direction: column;
            align-self: flex-start;
            width: 100%;
            & li {
                align-items: center;
                justify-content: flex-start;
                width: 80%;
                gap: 10px;
                & a {
                    transition: .2s;
                    &:hover {
                        scale: 1.02;
                        transition: .2s;
                    }
                }
            }
        }
    }
}